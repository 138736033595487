import React from "react";

import PersonCard from "../card/personCard/personCard";

import "./courtageInterlocuteursGrid.scss";

export default function CourtageInterlocuteursGrid({personList}) {
  const list = personList.slice(0, 5);
  return (
    <section className="courtage-interlocuteurs-grid">
      <div className="courtage-interlocuteurs-grid__top">
        {list.slice(0, 1).map((person, i) => {
          return (
            <PersonCard
              key={i}
              person={person}
              className="courtage-interlocuteurs-grid__person-card"
              gridArea={String.fromCharCode(i + 65)}
            />
          );
        })}
      </div>
      <div className="courtage-interlocuteurs-grid__bottom">
        {list.slice(1, 5).map((person, i) => {
          return (
            <PersonCard
              key={i}
              person={person}
              className="courtage-interlocuteurs-grid__person-card"
              gridArea={String.fromCharCode(i + 66)}
            />
          );
        })}
      </div>
    </section>
  );
}
