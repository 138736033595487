import React from "react";
import {graphql} from "gatsby";
import {Helmet} from "react-helmet";

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";

import BlocImage from "../../components/dynamicZone/blocImage/blocImage";
import HeaderImage from "../../components/header/headerImage/headerImage";
import TextImageContent from "../../components/textImageContent/textImageContent";
import CourtageInterlocuteursGrid from "../../components/courtageInterlocuteursGrid/courtageInterlocuteursGrid";

import "./style.scss";

import imgCourtage from "../../images/espace-courtage/header_v2.jpg";

import imgBlocImage from "../../images/espace-courtage/photo-bloc-image_v2.jpg";

import photo1 from "../../images/espace-courtage/Photo-1.webp";
import photo2 from "../../images/espace-courtage/Photo-2.webp";
import photo3 from "../../images/espace-courtage/Photo-3.webp";

const EspaceCourtage = ({data, location}) => {
    const personList = data.allStrapiEspaceCourtage.nodes[0].Personnes;
    return (
        <>
            <Helmet>
                <body className="espace-courtage"/>
            </Helmet>
            <Layout>
                <SEO title="Espace Courtage"
                     description="Un espace dédié à nos courtiers partenaires : informations pratiques, demande de partenariat ou accès à leur espace en ligne."/>
                <HeaderImage
                    title="Espace Courtage"
                    text="Notre équipe courtage est composée de spécialistes dédiés à ce réseau de distribution. Ils vous accompagnent et vous conseillent pour offrir service, conseil et performance à vos clients du BTP."
                    imagePath={imgCourtage}
                    location={location}
                    crumbLabel="Espace Courtage"
                />
                <section className="espace-courtage__section">
                    <TextImageContent
                        imgPath={photo1}
                        isImgFirst={false}
                        imgAlt="discussion"
                    >
                        <h3 className="u-mb-big">Une équipe d’experts à votre service.</h3>
                        <p>
                            Quelle que soit la taille de votre structure, nous sommes à même
                            de <strong>répondre rapidement et efficacement à toutes vos demandes</strong> tant
                            en assurance construction qu'en ce qui concerne l'assurance des
                            biens, des responsabilités et des flottes automobiles de vos
                            clients.
                        </p>
                    </TextImageContent>
                    <TextImageContent
                        imgPath={photo2}
                        isImgFirst={true}
                        imgAlt="bureau"
                    >
                        <h3 className="u-mb-big">
                            Un espace prêt pour vous accueillir.
                        </h3>
                        <p>
                            L'Auxiliaire a dédié son deuxième étage au <strong>service courtage et à l’accueil de ses
                            courtiers partenaires</strong>.
                            La place qu'occupe nos courtiers est chaque jour plus importante pour l'activité de notre
                            mutuelle,
                            nous nous devions de vous proposer <strong>un lieu chaleureux</strong> et fonctionnel afin
                            de vous accueillir.
                        </p>
                    </TextImageContent>
                    <TextImageContent
                        imgPath={photo3}
                        isImgFirst={false}
                        imgAlt="réunion"
                    >
                        <h3 className="u-mb-big">
                            Un service personnalisé pour chaque courtier.
                        </h3>
                        <p>
                            Souscription, indemnisation, comptabilité..., notre équipe
                            Courtage possède toutes les ressources nécessaires à <strong>la gestion
                            rapide, efficace et personnalisée de vos contrats</strong> pour compte de
                            vos clients. Avec nous, vous êtes entre de bonnes mains.
                        </p>
                    </TextImageContent>
                </section>
                <BlocImage
                    title="<strong>Vous souhaitez distribuer nos contrats&nbsp;?<strong>"
                    text="Être accompagné par un assureur que vous connaissez et qui vous connaît, ça fait toute la différence."
                    btnText="Devenir Partenaire"
                    imagePath={imgBlocImage}
                    btnUrl="/devenir-partenaire"
                />
                <section className="espace-courtage__section-grid">
                    <h2>
                        Découvrez qui<br className="not-on-mobile"/> seront vos<br className="not-on-mobile"/>
                        <strong>interlocuteurs</strong>&nbsp;?
                    </h2>
                    <CourtageInterlocuteursGrid personList={personList}/>
                </section>
            </Layout>
        </>
    );
};

export const query = graphql`
  query EspaceCourtagePageQuery {
    allStrapiEspaceCourtage {
      nodes {
        Personnes {
          Nom
          Poste
          Prenom
          Photo {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
        }
      }
    }
  }
`;

export default EspaceCourtage;
